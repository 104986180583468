body {
	background:  #000;
}
.mainWrap{
	height: 100vh;
}
.headerWrap{
	text-align: center;
    margin-top: 37px;
    img{
    	width: 134px;
    }
}
.img-holder{
	text-align: center;
	margin-top: 24px;
	position: relative;
}
.center-text{
	height: 100%;
	position: absolute;
	top: 50%;
	left: 50%; 
	text-align: left;
	transform: translate(-50%, -50%);
	span{
		font-size: 36px;
		height: 100%;
		color: #fff;
		display: -ms-flexbox;
	    display: flex; 
	    -ms-flex-align: center; 
	    align-items: left;
	    -ms-flex-direction: column;
	    flex-direction: column;
	    -ms-flex-pack: center;
	    justify-content: center;
	    letter-spacing: 1px;
	    line-height: 0.9em;
	    font-family: 'akzidenz-grotesk_bqcondensed';

	    strong{
		    font-family: 'akzidenz-grotesk_bqBdCn'; 
	    }
	}
} 

.mobile-bg{
	display: none;
}