@include mobile{

	.center-text {
		transform: translate(-39%,-89%);
		span{
			justify-content: flex-end;
		}
	}
	.img-holder{
		position: absolute;
	    bottom: 43px;
	    padding-left: 30px;
	    padding-right: 30px;
	    padding-bottom: 0;
	    width: 100%;
	}
	.mainWrap{
		height: calc(100vh - 73px);
	} 
	.mobile-hidden{
		display: none;
	}
	.mobile-bg{
		display: block;
   		margin: 0 auto;
	}

} 