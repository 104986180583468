@font-face {
    font-family: 'akzidenz-grotesk_bqBdCn';
    src: url('../fonts/akzidenzgrotesk-boldcond-webfont.woff2') format('woff2'),
         url('../fonts/akzidenzgrotesk-boldcond-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'akzidenz-grotesk_bqcondensed';
    src: url('../fonts/akzidenzgrotesk-cond-webfont.woff2') format('woff2'),
         url('../fonts/akzidenzgrotesk-cond-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}



@font-face {
    font-family: 'akzidenz-grotesk_bqbold';
    src: url('../fonts/akzidenzgrotesk-bold-webfont.woff2') format('woff2'),
         url('../fonts/akzidenzgrotesk-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




